import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
// import TarmoImage from 'assets/Tuote_Tarmo.png';
// import VIPImage from 'assets/Tuote_VIPVaraus.png';
// import PateImage from 'assets/Tuote_Pate.png';

const Products: React.FC = () => {
    return (
        <>
            <Grid container style={{ marginTop: 100 }}>
                <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                        Tuotteet
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 70 }}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="textSecondary">
                        Tässä on esimerkkeinä kuvattuna muutamia tuoteita mitä DataCodex on toteuttanut.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 100 }}>
                <Grid item sm={9} style={{ marginRight: 20 }}>
                    <Typography variant="h3" gutterBottom id="tarmo">
                        Tarmo
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        TAlkoolaisten Resursointi ja Majoitus Ohjelmisto - TARMO on toiminnanohjausjärjestelmä
                        IK-opiston vuosittaisen Juhannuskonferenssin talkootyön hallintaan. Tapahtuma nojautuu pitkälti
                        noin 2000 talkoolaisen työpanokseen.
                        <br />
                        <br />
                        Talkoolaiset ilmoittautuvat etukäteen web-lomakkeella tai tapahtumassa paikan päällä
                        työtehtäviin. Järjestelmään kirjataan heidän työpisteensä ja työpäivät. Mikäli talkoolainen
                        tarvitsee majoituksen, sekin hoidetaan Tarmon kautta. Majoituksia hallitaan vuosittain noin 500
                        henkilön osalta. Järjestelmän kautta myydään myös juhlavieraille yhteismajoituspaikkoja. Kun
                        talkoolainen on ilmoittautunut työhön, hänelle tulostetaan viivakoodilla varustettu työkortti,
                        joka toimii sekä henkilötunnistuksessa, että esim. ruokailuedun valvonnassa. Talkootehtävät
                        tallentuvat historiaan, jolloin henkilön taustat on jo tiedossa, kun hän seuraavana vuonna on
                        ilmoittautumassa talkoisiin.
                        <br />
                        <br />
                        Työpisteiden esimiehet saavat ohjattua oman näkymänsä kautta talkoolaiset tehtäviinsä. Esimiehet
                        voivat kirjata myös työpisteen talkoolaistarpeet päiväkohtaisesti, jota tietoa
                        juhannuskonferensin työhönotto käyttää ohjatessaan talkoolaisia tehtäviin.
                        <br />
                        <br />
                        Järjestelmästä saadaan myös monipuoliset tilasto- ja käyttöraportit IK-opiston tarpeisiin.
                        <br />
                        <br />
                        Tarmon ensimmäinen versio on kehitetty vuonna 2004 ja vuonna 2005 siihen lisättiin
                        majoitustoiminnot. Sen jälkeen on tehty vain pienimuotoisia päivityksiä ja uudistuksia.
                        Järjestelmä onkin päätetty uudistaa kokonaisuudesssaan. Tämä kehitystyö on käynnistetty vuonna
                        2020.
                    </Typography>
                </Grid>
                <Grid item sm={3} style={{ marginRight: -20 }}>
                    {/* <img src={TarmoImage} className="contentImage" alt="Tarmo" /> */}
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
            <Grid container>
                <Grid item sm={3} style={{ marginRight: 20 }}>
                    {/* <img src={PateImage} className="contentImage" alt="Pate" /> */}
                </Grid>
                <Grid item sm={9} style={{ marginRight: -20 }}>
                    <Typography variant="h3" style={{ background: '#ffffffaa' }} gutterBottom>
                        Pate
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        PAstorien TEot - PATE on web-lomake/sovellus seurakunnan palkatun henkilöstön työajanseurantaan.
                        <br />
                        <br />
                        Patessa on päivät jaettu kolmeen moduuliin: aamupäivä, iltapäivä, ilta. Moduuleihin voidaan
                        kirjata ko. ajankohdan työn sisältö (sisältökuvauksina käytetään yhden kirjaimen tunnisteita,
                        esim. S saarnan valmistus, M matka jne.). Sovellus laskee vapaiden ja työpäivien määrät
                        automaattisesti, ja siitä saa tulostettua raportit työntekijäkohtaisesti.
                        <br />
                        <br />
                        Työterveyshuollon palautteen mukaan tämä on hyvä tapa kirjata työntekijöiden työtä ja vapaita
                        muistiin, ettei pastorin työssä helposti unohtuvat vapaat jää pitämättä.
                    </Typography>
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
            <Grid container>
                <Grid item sm={9}>
                    <Typography variant="h3" gutterBottom>
                        Varauskalenteri
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Varauskalenteri on web-sovellus tilojen varaamiseksi. Yksinkertainen kalenteri, jossa jokaiselle
                        tilalle on oma välilehti. Varaukseen voi liittää lisätietoja tai vain kellonajan, jolloin tila
                        on varattuna.
                    </Typography>
                </Grid>
                <Grid item sm={3}>
                    {/* <img src={VIPImage} className="contentImage" alt="VIP Varaus" /> */}
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
        </>
    );
};

export default Products;
