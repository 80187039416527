import React, { useState, ChangeEvent } from 'react';
import {
    Typography,
    Grid,
    Box,
    makeStyles,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    TextField,
    Button,
    FormLabel,
    Link
} from '@material-ui/core';
import emailjs from '@emailjs/browser';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        minWidth: 345
    },
    media: {
        height: 330
    },
    formfield: {
        width: 350
    }
});

interface ContactCardProps {
    name: string;
    email: string;
    image: string;
    linkedin: string;
}

const ContactCard: React.FC<ContactCardProps> = ({ name, email, image, linkedin }: ContactCardProps) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia className={classes.media} title={name} image={image} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <Link target="_blank" href={`https://www.linkedin.com/in/${linkedin}/`}>{`LinkedIn`}</Link>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

interface FormData {
    name: string;
    email: string;
    message: string;
}

const Contacts: React.FC = () => {
    const classes = useStyles();
    const [formdata, setFormdata] = useState<FormData>({ name: '', email: '', message: '' });
    const [messageSent, setMessageSent] = useState(false);
    const [sending, setSending] = useState(false);

    const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        setSending(true);
        event.preventDefault();
        const target = event.currentTarget;

        emailjs.init(process.env.REACT_APP_EMAILJS_TOKEN || '');

        const templateParams = {
            sender_email: formdata.email,
            sender: formdata.name,
            subject: 'WEB Contact Form',
            message_html: formdata.message
        };

        emailjs.send('eurovps_smtp', 'template_2mjm0Aiw', templateParams).then(
            () => {
                //console.log('SUCCESS!', response.status, response.text);'
                setMessageSent(true);
                setSending(false);
                target.reset();
                setInterval(() => {
                    setMessageSent(false);
                }, 2000);
            },
            error => {
                setSending(false);
                console.log('FAILED...', error);
            }
        );
    };

    const onNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setFormdata({ ...formdata, name: event.target.value });
    };

    const onEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setFormdata({ ...formdata, email: event.target.value });
    };

    const onMessageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setFormdata({ ...formdata, message: event.target.value });
    };

    return (
        <>
            <Grid container style={{ marginTop: 80 }}>
                <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                        Yhteystiedot
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 50 }} spacing={4} justify="space-evenly">
                <Grid item>
                    <ContactCard
                        name="Sampo Antila"
                        email="sampo.antila@datacodex.fi"
                        image="/static/sampo.jpg"
                        linkedin="sampoantila"
                    />
                </Grid>
                <Grid item>
                    <ContactCard
                        name="Riku Kuusisto"
                        email="riku.kuusisto@datacodex.fi"
                        image="/static/riku.jpg"
                        linkedin="riku-kuusisto-630589a1"
                    />
                </Grid>
            </Grid>
            <Box style={{ margin: 80 }} />
            <Grid container style={{ marginTop: 80 }}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        DataCodexin julkinen PGP avain
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography gutterBottom>
                        Julkisella avaimella voit salata DataCodexille toimitettavan aineiston tai viestin esim.{' '}
                        <Link href="https://www.gpg4win.org/" target="_blank">
                            Gpg4win
                        </Link>{' '}
                        ohjelmalla.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Link href="/static/datacodex_pub.asc" download>
                            <VpnKeyIcon fontSize="small" /> DataCodexin julkinen avain
                        </Link>
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Avaimen sormenjälki:
                    </Typography>
                    <Typography color="textSecondary">0DA5 0B94 C63A 9054 363C E19A B739 1417 5639 454A</Typography>
                </Grid>
            </Grid>
            <Box style={{ margin: 80 }} />
            <Card>
                <CardContent>
                    <form autoComplete="off" id="contact-form" method="POST" onSubmit={submitForm}>
                        <Grid container style={{ margin: 10 }} spacing={4}>
                            <Grid item xs={12} style={{ marginBottom: 20 }}>
                                <Typography variant="h4">Yhteydenotto</Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <FormLabel>Nimi</FormLabel>
                            </Grid>
                            <Grid item sm={10}>
                                <TextField
                                    id="name"
                                    placeholder="Kirjoita nimesi"
                                    className={classes.formfield}
                                    onChange={onNameChange}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <FormLabel>S&auml;hk&ouml;posti</FormLabel>
                            </Grid>
                            <Grid item sm={10}>
                                <TextField
                                    id="email"
                                    placeholder="Kirjoita sähköpostiosoitteesi"
                                    className={classes.formfield}
                                    onChange={onEmailChange}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <FormLabel>Viesti</FormLabel>
                            </Grid>
                            <Grid item sm={10}>
                                <TextField
                                    id="message"
                                    multiline
                                    rows={4}
                                    placeholder="Kirjoita viestisi/kysymykseksi/kommenttisi"
                                    className={classes.formfield}
                                    onChange={onMessageChange}
                                />
                            </Grid>
                            <Grid item sm={2}></Grid>
                            <Grid item sm={10}>
                                <Button variant="contained" color="primary" type="submit" disabled={sending}>
                                    Lähetä
                                </Button>
                            </Grid>
                            <Grid item sm={2}></Grid>
                            <Grid item sm={10}>
                                {messageSent && (
                                    <Typography variant="h5" style={{ color: 'darkgreen' }}>
                                        <b>Viesti lähetty!</b>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Box style={{ margin: 70 }} />
        </>
    );
};

export default Contacts;
