import React from 'react';
import { Container, Grid, Typography, Paper, createMuiTheme, Link } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import logo from 'assets/sp_logo.png';

const getThemeColor = (color?: 'primary' | 'secondary' | 'default'): string => {
    let htmlColor = '';

    switch (color) {
        case 'primary':
            htmlColor = '#674ea7';
            break;
        case 'secondary':
            htmlColor = '#868FA0';
            break;
        default:
            htmlColor = '#000000';
            break;
    }

    return htmlColor;
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: getThemeColor('primary'),
            contrastText: '#ffffff'
        },
        secondary: {
            main: getThemeColor('secondary'),
            contrastText: '#ffffff'
        },
        contrastThreshold: 3
    },
    typography: {
        h4: {
            color: getThemeColor('primary'),
            fontWeight: 300
        },
        h5: {
            color: getThemeColor('primary'),
            fontWeight: 400
        }
    }
});

const SPMobileAuthPolicyView: React.FC = () => (
    <ThemeProvider theme={createMuiTheme(theme)}>
        <div className="sp-container">
            <Container className="sp-app-content-wrapper">
                <Paper>
                    <Header />
                    <Container className="sp-app-content-container">
                        <MobileAuthPolicyView />
                    </Container>
                </Paper>
            </Container>
            <Footer />
        </div>
    </ThemeProvider>
);

const Footer: React.FC = () => (
    <footer className="sp-footer MuiPaper-elevation3">
        <Container maxWidth="sm">
            <Typography variant="body2" color="textSecondary">
                &copy; {new Date().getFullYear()}{' '}
                <Link color="inherit" href="https://suomenhelluntaikirkko.fi/">
                    Suomen Helluntaikirkko
                </Link>
                {' - '}
                <Link href="mailto:toimisto@helluntaikirkko.fi">toimisto@helluntaikirkko.fi</Link>
            </Typography>
        </Container>
    </footer>
);

const Header: React.FC = () => (
    <header className="sp-header">
        <Grid container justify="center" alignItems="center">
            <Grid item>
                <img className="sp-logo" style={{ marginTop: 15 }} src={logo} alt="logo" />
            </Grid>
            <Grid item>Sähköpaimen</Grid>
        </Grid>
    </header>
);

const MobileAuthPolicyView: React.FC = () => (
    <Container>
        <Grid container direction="column" spacing={3} style={{ marginTop: 24 }}>
            <Grid item>
                <Typography variant="h4">Sähköpaimen sÄppi -mobiilisovelluksen tietosuojakäytäntö</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    Tervetuloa tutustumaan Sähköpaimen sÄppi -mobiilisovelluksen (&quot;sovellus&quot;)
                    tietosuojakäytäntöön. Sovellus on osa Sähköpaimen-jäsenrekisteriä.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">1. Mihin sovellusta käytetään</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    Sovellus aktivoidaan Sähköpaimen-jäsenrekisterin käyttäjätunnuksen rekisteröinnin yhteydessä
                    lukemalla qr-koodi Sähköpaimen-jäsenrekisterin rekisteröintisivulta. Sovellus tuottaa käyttäjän
                    henkilökohtaisia vahvistuskoodeja sisäänkirjautumista varten. Koodit ovat voimassa yhden minuutin,
                    jonka jälkeen luodaan uusi koodi.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">2. Mitä tietoja sovelluksessa kerätään</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    Sovellus tallentaa aktivointivaiheessa käyttäjätunnuksen ja qr-koodista saamansa satunnaisen
                    merkkijonon. Muita tietoja käyttäjästä tai sovelluksen käytöstä tai toiminnasta ei tallenneta.
                    Käyttäjätunnus on käyttäjän itse valitsema. Se voi sisältää henkilön nimen, mikäli käyttäjä on sen
                    sisällöksi kirjoittanut.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">3. Miten tiedot on tallennettu</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    Sovellus tallentaa keräämänsä tiedot mobiililaitteen suojattuun tietovarastoon. Suojattu
                    tietovarasto on vain sovelluksen käytettävissä, muut sovellukset eivät pääse lukemaan tai
                    kirjoittamaan ko. tietoihin.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">4. Tietojen jakaminen</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    Sovelluksesta ei jaeta tietoja mihinkään. Käyttäjä itse kirjoittaa vahvistuskoodin Sähköpaimen
                    -jäsenrekisteriin kirjautuessaan. Kirjautumisessa vahvistuskoodin oikeellisuus tarkistetaan, mutta
                    sitä ei tallenneta.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h4">Lisätietoja</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    Lisätietoja sovelluksesta ja sen tietosuojakäytännöstä antaa Suomen Helluntaikirkko.
                </Typography>
                <br />
                <Typography variant="body1">
                    <b>Suomen Helluntaikirkko</b>
                </Typography>
                <Typography variant="body1">Puhelin: 045 632 7848</Typography>
                <Typography variant="body1">Sähköpostiosoite: toimisto@helluntaikirkko.fi</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    Käytäntö päivitetty 30.6.2021
                </Typography>
            </Grid>
        </Grid>
    </Container>
);

export default SPMobileAuthPolicyView;
