import React from 'react';
import { Grid, Typography, Box, Link } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const PageNotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Grid container style={{ marginTop: 80 }}>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom>
                    Sivua ei löytynyt!
                </Typography>
                <Box style={{ margin: 60 }} />
                <Link
                    variant="h5"
                    onClick={(): void => {
                        navigate('/');
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    Palaa etusivulle...
                </Link>
                <Box style={{ margin: 40 }} />
            </Grid>
        </Grid>
    );
};

export default PageNotFound;
