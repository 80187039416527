import React from 'react';
import 'assets/App.scss';
import { Container } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';
import { BrowserRouter, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import SPMobileAuthPolicyView from './SPMobileAuthPolicyView';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <AppLayout />
        </BrowserRouter>
    );
};

const AppLayout: React.FC = () => {
    const location = useLocation();

    const mobilePolicyPage = location.pathname === '/spMobileAuthPolicy';

    if (mobilePolicyPage) {
        return <SPMobileAuthPolicyView />;
    }

    return (
        <>
            <Header />
            <Container style={{ paddingLeft: 40, paddingRight: 40 }}>
                <AppRoutes />
            </Container>
            <Footer />
        </>
    );
};

export default App;
