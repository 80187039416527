import React from 'react';
import { Typography, Grid, Link, Box } from '@material-ui/core';
import CodexImage from 'assets/codex.jpg';
import ConffaImage from 'assets/conffa.jpg';
import { useNavigate } from 'react-router-dom';

const Company: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Grid container style={{ marginTop: 80 }}>
                <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                        DataCodexin tarina
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 80 }}>
                <Grid item xs={12} sm={7} style={{ marginRight: 20 }}>
                    <Typography variant="h3" gutterBottom>
                        Alussa oli Tarmo
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        IK-opistolla Keuruulla pidetään vuosittain helluntaiherätyksen juhannuskonferenssi. Työskentelin
                        talkoolaisena tapahtumaorganisaatiossa IT-puolella ja ja havaitsin, että suuri tapahtuma on
                        vailla sen tarpeita palvelevaa ohjausjärjestelmää. Tarjouduin tekemään tarvittavan ohjelmiston
                        talkoo-periaatteella.
                        <br />
                        <br />
                        Yhdessä juhlakanslian vastuuhenkilöiden kanssa teimme määrittelyn ohjelmiston pohjaksi. Silloin
                        ei tunnettu ketteriä menetelmiä, mutta näin toimimme jo vuosituhannen taitteessa. Järjestelmä
                        sai nimekseen Tarmo puoliksi vahingossa, kun silloinen konferenssitoimikunnan puheenjohtaja ei
                        muistanut nimeäni oikein, vaan kutsui Tarmoksi. Siitä tuli vitsillä projektin työnimi, mutta se
                        jäi pysyväksi, kun parempaakaan ei keksitty. Mutta sovitaan, että Tarmo on TAlkoolaisten
                        Resursointi- ja MajoitusOhjelmisto.{' '}
                        <Link
                            variant="h6"
                            onClick={(): void => {
                                navigate('/products/#tarmo');
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Lue lisää Tarmosta...
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5} style={{ marginRight: -20 }}>
                    <img src={ConffaImage} className="contentImage" alt="Seurakunnan tilaisuus" />
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
            <Grid container style={{ marginTop: 100 }}>
                <Grid item xs={12} sm={5} style={{ marginRight: 20 }}>
                    <img src={CodexImage} className="contentImage" alt="Seurakunnan tilaisuus" />
                </Grid>
                <Grid item xs={12} sm={7} style={{ marginRight: -20 }}>
                    <Typography variant="h3" gutterBottom>
                        ...sitten tuli DataCodex
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Perustin toiminimi DataCodexin vuonna 2005. Sen tarkoituksena oli toimia virallisena tahona, kun
                        Tarmon myötä aloin tehdä muitakin sovelluksia seurakunnille.
                        <br />
                        <br />
                        Yrityksen nimi löytyi Carsten Peter Thieden kirjoittamasta kirjasta Kala Rooman keisarille
                        (PerusSanoma Oy, 1999). Siellä kerrotaan, kuinka varhaiset kristityt olivat ajan hermolla
                        levittäessään sanomaa. He ottivat käyttöön uuden kirjamuodon, koodeksin, joka oli perinteisiin
                        kirjakääröihin verrattuna paljon käytännöllisempi tapa tallentaa ja levittää tekstejä. Samalla
                        tavalla koodeksi-nimeä kantava yritys haluaa toimia Sanoman levittämisen tukena tarjoamalla
                        ajanmukaisia ratkaisuja käytännön tasolla.
                        <br />
                        <br />
                        DataCodex toimi 15 vuotta pienimuotoisesti oman toimen ohella. Se tuotti mm. domain- ja
                        web-hotellipalveluita sekä IT-tukea sovelluskehitystyön lisäksi.
                        <br />
                        <br />
                        DataCodexin yhtiömuoto muuttui osakeyhtiöksi kesällä 2020. Osakeyhtiö mahdollistaa paremmin
                        yrityksen nyt laajenemassa olevan toiminnan.
                    </Typography>
                    <Box style={{ margin: 60 }} />
                    <Typography variant="h5" gutterBottom color="textSecondary">
                        <i>Sampo</i>
                        <br />
                        DataCodexin perustaja
                    </Typography>
                    <Box style={{ margin: 60 }} />
                </Grid>
            </Grid>
        </>
    );
};

export default Company;
