import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import PageNotFound from './PageNotFound';
import Contacts from './Contacts';
import Company from './Company';
import Products from './Products';
import SPMobileAuthPolicyView from './SPMobileAuthPolicyView';

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/company" element={<Company />} />
        <Route path="/products" element={<Products />} />
        <Route path="/spMobileAuthPolicy" element={<SPMobileAuthPolicyView />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
);

export default AppRoutes;
