import React from 'react';
import { Typography, Link, Grid, Card, CardContent, makeStyles, Container } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GitHubIcon from '@material-ui/icons/GitHub';
import BusinessIcon from '@material-ui/icons/Business';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import YrittajatLogo from 'assets/yrittajat_jasenyritys_2021.png';

const useStyles = makeStyles({
    root: {
        background: 'transparent',
        border: 0,
        textAlign: 'left'
    },
    title: {
        fontSize: 20,
        padding: '10px 0'
    },
    pos: {
        marginBottom: 12
    },
    iconcol: {
        width: 40
    }
});

const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <footer className="footer">
            <Container>
                <>
                    <div className="pseudoRelativeDiv">
                        <div className="yrittajatLogoContainer">
                            <Link href="https://www.yrittajat.fi" target="_blank">
                                <img
                                    src={YrittajatLogo}
                                    className="yrittajatLogo"
                                    alt="Yritt&auml;j&auml;t j&auml;senyritys"
                                />
                            </Link>
                        </div>
                    </div>
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Typography variant="h5" gutterBottom className={classes.title}>
                                        <Grid container>
                                            <Grid item className={classes.iconcol}></Grid>
                                            <Grid item>DataCodex Oy</Grid>
                                        </Grid>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}>
                                                <RoomIcon />
                                            </Grid>
                                            <Grid item>
                                                Vieremänkatu 23
                                                <br />
                                                05900 Hyvinkää
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}>
                                                <PhoneIcon />
                                            </Grid>
                                            <Grid item>045 111 4505</Grid>
                                        </Grid>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}></Grid>
                                            <Grid item style={{ marginRight: 10 }}>
                                                <Link
                                                    href="https://www.linkedin.com/company/datacodex"
                                                    target="_blank"
                                                    rel="noopener"
                                                >
                                                    <LinkedInIcon />
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                <Link
                                                    href="https://github.com/datacodex"
                                                    target="_blank"
                                                    rel="noopener"
                                                >
                                                    <GitHubIcon />
                                                </Link>
                                            </Grid>
                                            <Grid item xs={8}></Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        className={classes.title}
                                        style={{ color: 'transparent' }}
                                    >
                                        {'- '}
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}>
                                                <BusinessIcon />
                                            </Grid>
                                            <Grid item>Y-tunnus: 3144407-7</Grid>
                                        </Grid>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}>
                                                <AccountBalanceIcon />
                                            </Grid>
                                            <Grid item>FI33 8146 9710 2089 59</Grid>
                                        </Grid>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}>
                                                <EmailIcon />
                                            </Grid>
                                            <Grid item>
                                                <Link color="inherit" href="mailto:info@datacodex.fi">
                                                    info@datacodex.fi
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        className={classes.title}
                                        style={{ color: 'transparent' }}
                                    >
                                        {'- '}
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}>
                                                <ReceiptIcon />
                                            </Grid>
                                            <Grid item>
                                                Verkkolaskuosoite:
                                                <br />
                                                003731444077
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary" component="div">
                                        <Grid container>
                                            <Grid item className={classes.iconcol}>
                                                <LaptopWindowsIcon />
                                            </Grid>
                                            <Grid item>
                                                Operaattoritunnus:
                                                <br />
                                                Apix Messaging Oy (003723327487)
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <Typography variant="body2" color="textSecondary">
                                <Link
                                    variant="body2"
                                    color="textSecondary"
                                    href="https://webmail.datacodex.fi"
                                    target="_blank"
                                >
                                    &copy;
                                </Link>{' '}
                                {new Date().getFullYear()} DataCodex Oy{' '}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            </Container>
        </footer>
    );
};

export default Footer;
