import React from 'react';
import { Typography, Grid, Box, Link } from '@material-ui/core';
import TeamImage from 'assets/team_planning.jpg';
import GroupImage from 'assets/group_decision.jpg';
import BroadcastImage from 'assets/hhsrk_live.jpg';
import CodeImage from 'assets/code_vertical.jpg';
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Grid container style={{ marginTop: 80 }}>
                <Grid item xs={12}>
                    <Typography variant={'h2'} gutterBottom>
                        Ohjelmistoja kristillisille toimijoille
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 80 }} spacing={3}>
                <Grid item sm={7}>
                    <Typography variant="h3" gutterBottom>
                        Seurakunnat tarvitsevat tekniikkaa
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Meillä on maailman tärkein Sanoma. Sen tehokas välittäminen vaatii nykyaikana toimivat
                        digitaaliset työkalut ja toimintaympäristöt. Seurakuntien ja kristillisten järjestöjen yhtenä
                        haasteena on sopivien ohjelmistojen puute ja korkeat kustannukset. DataCodex tarjoaa
                        ohjelmistoratkaisuja asiakkaiden tarpeisiin räätälöidysti ja kustannustehokkaasti.
                    </Typography>
                </Grid>
                <Grid item sm={5}>
                    <img src={BroadcastImage} className="contentImage" alt="Seurakunnan tilaisuus" />
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <img src={TeamImage} className="contentImage" alt="Tiimityöskentelyä" />
                </Grid>
                <Grid item sm={6}>
                    <Typography variant="h3" gutterBottom>
                        Etsitään yhdessä toimivimmat ratkaisut
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Tarvitseeko seurakuntasi tilavarauskalenterin? Miten palkatun henkilöstön työajanseuranta
                        hoidetaan? Järjestättekö ison tapahtuman ilmoittautumisineen? DataCodex kuuntelee, mitä asiakas
                        tarvitsee ja toteuttaa tarvittavan sovelluksen. Työskentelytapana on pitää aktiivisesti yhteyttä
                        asiakkaaseen koko projektin ajan. Tiimityöskentelyllä saadaan parhaat tulokset ja tehdään juuri
                        oikeita asioita. Kehitystyössä käytetään ketteriä menetelmiä, jolloin asiakas näkee jatkuvasti
                        ajantasaisen tilanteen, miten projekti etenee.
                    </Typography>
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <Typography variant="h3" gutterBottom>
                        Yhteistyö on enemmän kuin osiensa summa
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Seurakunnilla ja kristillisillä järjestöillä on erityiset tarpeet, mutta ne ovat keskenään usein
                        samankaltaiset. DataCodex pyrkii yhdistämään tällaiset tahot, jotta ohjelmistokehityksen
                        kustannukset yhtä asiakasta kohti pysyvät kohtuullisina.
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <img src={GroupImage} className="contentImage" alt="Tiimityöskentelyä" />
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
            <Grid container spacing={3}>
                <Grid item sm={3}>
                    <img src={CodeImage} className="contentImage" alt="Lähdekoodia" />
                </Grid>
                <Grid item sm={9}>
                    <Typography variant="h3" gutterBottom>
                        Ammattitaitoiset tekijät
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        DataCodexin ammattilaiset ovat tietotekniikan moniosaajia kymmenien vuosien kokemuksella.
                        Käytämme uusimpia teknologioita ja kestäviä ratkaisuja aidosti asiakastarpeeseen. EU:n
                        tietosuoja-asetuksen tuomien vaatimusten huomioiminen henkilötietojen käsittelyssä on
                        ohjelmistokehityksessä itsestäänselvyys.
                        <br />
                        <br />
                        Teknisen osaamisen lisäksi asiantuntemusta on karttunut seurakuntaympäristöjen taloudesta,
                        hallinnosta ja johtamisesta sekä monenlaisten isojen ja pienten tapahtumien järjestämisestä.
                    </Typography>
                    <Box style={{ margin: 60 }} />
                    <Link
                        variant="h5"
                        onClick={(): void => {
                            navigate('/contacts');
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        Ota yhteyttä niin jutellaan lisää
                    </Link>
                </Grid>
            </Grid>
            <Box style={{ margin: 120 }} />
        </>
    );
};

export default Dashboard;
