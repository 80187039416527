import React from 'react';
import { Typography, Container, Grid, ButtonGroup, Button, Hidden, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | SVGElement>(null);

    const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <header className="header">
            <Container>
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="h3" className="header_company_name">
                            <Link color="inherit" to="/" className="no_underline">
                                DataCodex
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ textAlign: 'right' }}>
                        <Hidden smUp>
                            <MenuIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                className="header_menu"
                            >
                                <MenuItem onClick={handleClose}>
                                    <Link to="/" className="no_underline">
                                        Etusivu
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link to="/company" className="no_underline">
                                        Yritys
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link to="/products" className="no_underline">
                                        Tuotteet
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link to="/contacts" className="no_underline">
                                        Yhteystiedot
                                    </Link>
                                </MenuItem>
                            </Menu>
                        </Hidden>
                        <Hidden smDown>
                            <ButtonGroup variant="text">
                                <Button href="/company" color="inherit">
                                    Yritys
                                </Button>
                                <Button href="/products" color="inherit">
                                    Tuotteet
                                </Button>
                                <Button href="/contacts" color="inherit">
                                    Yhteystiedot
                                </Button>
                            </ButtonGroup>
                        </Hidden>
                    </Grid>
                </Grid>
            </Container>
        </header>
    );
};

export default Header;
